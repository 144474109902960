<template>
  <b-row>
    <b-col lg="3" >
      <b-card-actions title="主菜单" @refresh="refreshtree()" ref="tree">
        <vue-perfect-scrollbar  ref="refChatLogPS" :settings="perfectScrollbarSettings" class="menu_main scroll-area">
        <draggable v-model="list" class="list-group list-group-flush cursor-move" tag="ul">
          <transition-group type="transition" name="flip-list">
            <b-list-group-item v-for="listItem in list" :key="listItem.id" tag="li">
              <div class="d-flex" @click="getTree(listItem)">
                <b-avatar class="mr-1" variant="primary">
                  <span class="d-flex align-items-center">
                    <feather-icon :icon="listItem.icon" size="20"/>
                  </span>
                </b-avatar>
                <div class="ml-25">
                  <b-card-text class="font-weight-bold mb-0">
                    {{ listItem.name }}
                  </b-card-text>
                  <small>目前有{{ listItem.zlm }}个子栏目</small>
                </div>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
        </vue-perfect-scrollbar>
        <!-- <prism language="javascript"  class="rounded mt-2" >list: {{ list }}</prism> -->
      </b-card-actions>
    </b-col>
    <b-col lg="9" >
          <b-card-actions title="菜单管理" @refresh="refreshStop()" ref="refreshCard">
            <b-row>
              <b-col lg="7" class="mb-1">
                <b-button-group>
                  <b-button variant="primary" class="btn-icon" v-b-toggle.sidebar-1  @click="clearfrom()"><feather-icon
                      icon="PlusCircleIcon" /></b-button>
                  <b-button variant="primary" class="btn-icon" @click="selectAllRows()"><feather-icon
                      icon="CheckCircleIcon" />&nbsp;<span class="align-middle">{{ select_mode }}</span></b-button>
                  <b-button variant="primary" class="btn-icon" @click="deleteAllRows()"><feather-icon
                      icon="Trash2Icon" />&nbsp;<span class="align-middle">批量删除</span></b-button>
                </b-button-group>
              </b-col>
              <b-col lg="5" class="mb-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text><feather-icon icon="SearchIcon" /></b-input-group-prepend>
                  <b-form-input placeholder="菜单名称" v-model="search_name" />
                  <b-input-group-append><b-button variant="outline-primary"
                      @click="get_search()">检索</b-button></b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <b-table ref="selectableTable" @row-selected="onRowSelected" selectable select-mode="multi" class="tabledata"
          striped hover responsive="sm" :items="items" :fields="fields" sticky-header no-border-collapse>
              <template #cell()="data">
                  <span class="text-nowrap">
                  {{ data.value }}
                  </span>
              </template>
              <!-- 状态 -->
              <template #cell(flg)="data">
                <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.flg)}`" class="text-capitalize">
                  <span v-if="data.item.flg == 1">正常</span>
                  <span v-else>隐藏</span>
                </b-badge>
              </template>
              <template #cell(icon)="data">
                    <feather-icon :icon="data.item.icon" size="20"/>
              </template>
              <!-- 设置 -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>

                  <b-dropdown-item @click="get_edit(data.item.id)" v-b-toggle.sidebar-1>
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">编辑</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="get_del(data.item.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">删除</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>


            </b-table>

            <b-pagination v-model="currentPage" :total-rows="rows" first-number last-number prev-class="prev-item"
              next-class="next-item" align="left" :per-page="perPage"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
        
                <b-sidebar id="sidebar-1" shadow bg-variant="white" right backdrop width="auto"  title="菜单编辑">
                  <b-card class="add_card">
                  
                  <b-form>
                    <validation-observer ref="simpleRules">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="菜单名称" label-for="h-first-name" label-cols-md="4">
                          <validation-provider #default="{ errors }"  name="menu_name" rules="required">
                          <b-form-input v-model="FormData.name" id="h-first-name" placeholder="请输入菜单名称"  :state="errors.length > 0 ? false:null" />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="排序" label-for="h-email" label-cols-md="4">
                          <validation-provider #default="{ errors }"  name="menu_ord" rules="required">
                          <b-form-input v-model="FormData.ord" id="h-email" type="number" placeholder="排序" :state="errors.length > 0 ? false:null"/>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="路由地址" label-for="h-number" label-cols-md="4">
                          <validation-provider #default="{ errors }"  name="menu_path" rules="required">
                          <b-form-input v-model="FormData.path" id="h-number" placeholder="路由地址" :state="errors.length > 0 ? false:null"/>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="视图地址" label-for="h-number" label-cols-md="4">
                          <validation-provider #default="{ errors }"  name="menu_view" rules="required">
                          <b-form-input v-model="FormData.views" id="h-number" placeholder="视图地址" :state="errors.length > 0 ? false:null"/>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="图标" label-for="h-password" label-cols-md="4">
                          <validation-provider #default="{ errors }"  name="menu_icon" rules="required">
                          <b-form-input v-model="FormData.icon" id="h-password" placeholder="图标" :state="errors.length > 0 ? false:null" />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="上级栏目" label-for="h-number" label-cols-md="4">
                          <v-select v-model="FormData.pid" :options="options"   :reduce="val => val.value" />
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="包含操作" label-for="h-act" label-cols-md="4">
                          <b-form-tags v-model="acts" input-id="tags-basic"  placeholder="包含操作(回车加入)"  class="mb-2"/>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="启用状态" label-for="h-number" label-cols-md="4">
                          <b-form-checkbox
                              checked="true"
                              class="custom-control-primary"
                              name="check-button"
                              switch
                              v-model="flg"
                            >
                              <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                              </span>
                              <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                              </span>
                            </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12" style="text-align:center">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"   variant="primary" class="mr-1"
                          @click="save()">
                          保存
                        </b-button>
                      </b-col>  

                      
                    </b-row>
                    </validation-observer>
                  </b-form>
                  </b-card>
                </b-sidebar> 
          </b-card-actions>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BBadge, BCardText, BPagination, BButton, BPopover, BTable, BRow, BCol, BDropdown, BDropdownItem, BSidebar, VBToggle, BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormTags,
  BInputGroupAppend, BButtonGroup,
  BInputGroup, BInputGroupPrepend, BFormSelect
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BListGroupItem, BAvatar } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BFormSelect,
    BBadge,
    BSidebar,
    VBToggle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    BCard,
    BCardText,
    BCardActions,
    BButton,
    BPopover,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    draggable,
    BListGroupItem,
    BAvatar,
    Prism,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTags,
    BInputGroupAppend,
    BButtonGroup,
    BInputGroup,
    BInputGroupPrepend,
    ToastificationContent,
    VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID', },
        { key: 'name', label: '菜单名称', },
        { key: 'ord', label: '排序', },
        { key: 'path', label: '路由地址', },
        { key: 'views', label: '视图地址', },
        { key: 'icon', label: '图标', },
        { key: 'flg', label: '状态', },
        { key: 'actions', label: '设置', },

      ],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      planOptions: [
        { label: '隐藏', value: '0' },
        { label: '正常', value: '1' },
      ],
      items: [],
      table_height: 400,
      currentPage: 1,
      perPage: 20,
      rows: 0,
      list: [],
      selected: [],
      select_all: 0,
      select_mode: "全选",
      nameFilter: '',
      roleFilter: '',
      planFilter: '',
      search_name: '',
      detailstData: {},
      editData: {},
      pid:0,
      acts:[],
      FormData: {
        act: '',
        id: '',
        pid: "0",
        name: '',
        ord: '',
        path: '',
        views: '',
        icon: '',
        flg: '',
        selected: '',
        acts:[]
      },
      flg:true,
      options: [],
    }
  },
  created() {
    this.get_main_menu()
    this.get_main_menu2()
  },
  watch: {
    list: function (oldV, newV) {
      console.log(oldV,"oldV");
      console.log(newV,"newV");
      if(JSON.stringify(oldV)!=JSON.stringify(newV)&&newV!=""){
        this.update_main_menu()
      }
    },
  },
  methods: {
    clearfrom(){
      this.acts = []
      this.FormData= {
        act: '',
        id: '',
        pid: "0",
        name: '',
        ord: '',
        path: '',
        views: '',
        icon: '',
        flg: '',
        selected: '',
        acts:[]
      }
    },
    refreshtree() {
      this.get_main_menu();
    },
    get_search() {
      this.get_data()
    },
    getStause(val) {
      console.log(val, '22');
      this.planFilter = val
    },
    onRowSelected(items) {
      this.selected = items
      if(items.length>0){
        this.showToast('success', '选中了' + (items.length) + '条数据!')
      }
    },
    selectAllRows() {
      if (this.select_all == 1) {
        this.clearSelected()
        return
      }
      this.$refs.selectableTable.selectAllRows()
      this.select_all = 1
      this.select_mode = "取消全选"
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.select_all = 0
      this.select_mode = "全选"
    },
    refreshStop() {
      this.currentPage = 1
      this.search_name = ""
      this.get_data()
    },
    getTree(e) {
      this.pid = e.id
      this.get_data();
    },
    set_delids(id) {
      let val = this.selected
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      ids = ids.join();
      if(id){
        ids = id;
      }
      return ids;
    },
    del_data(ids) {
      let dataObj = {
        act: "del",
        id: ids,
      }
      this.$http.post('svr/admin_menu.php', dataObj)
        .then(res => {
          this.showToast('success', '删除成功')
          this.get_data()
        })
    },
    deleteAllRows(id) {
      let ids = this.set_delids(id)
      if (!this.selected.length > 0&&!id>0) {
        this.showToast('danger', '请先选择您要删除的数据!')
        return
      }
      this.$swal({
        title: '确定删除数据吗?',
        text: "此操作将不可逆,请谨慎操作",
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '我确定!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.del_data(ids)
        }
      })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '系统提示',
          icon: 'BellIcon',
          text: text,
          variant,
        },
      })
    },
    get_main_menu() {
      let dataObj = {
        act: "lst",
        pge: 1,
        cnt: 100,
        pid: 0,
      }
      this.$nextTick(() => {
        this.$refs["tree"].showLoading = true
      })
      this.$http.post('svr/admin_menu.php', dataObj)
        .then(res => {
          this.list = res.data.lst 
          this.getTree(this.list[0])  
          setTimeout(() => {
            this.$refs["tree"].showLoading = false
          }, 300)
        })
    },
    get_main_menu2() {
      let dataObj = {
        act: "get_menu2",
      }
      this.$http.post('svr/admin_menu.php', dataObj)
        .then(res => {
          this.options = res.data 
        })
    },
    update_main_menu() {
      let dataObj = {
        act: "ord",
        list: JSON.stringify(this.list), 
      }
      this.$http.post('svr/admin_menu.php', dataObj)
        .then(res => {
             this.showToast('success', '修改排序完成!')
        })
    },
    get_data() {
      let dataObj = {
        act: "lst",
        pge: this.currentPage,
        cnt: this.perPage,
        pid: this.pid,
        name: this.search_name ? this.search_name : ''
      }
      this.$nextTick(() => {
        this.$refs["refreshCard"].showLoading = true
      })
      this.$http.post('svr/admin_menu.php', dataObj)
        .then(res => {
          this.items = res.data.lst
          this.rows = res.data.cnt_all
          setTimeout(() => {
            this.$refs["refreshCard"].showLoading = false
          }, 300)
        })
    },
    get_del(val) {
      this.deleteAllRows(val)
    },
    get_edit(e) {
      let getdata = {
        act: 'get',
        id: e
      }
      this.$http.post('svr/admin_menu.php', getdata).then(res => {
        console.log(res, 'res');
        if (res.code == 20000) {
          this.acts=JSON.parse(res.data.acts)
          this.FormData = res.data
        }
      })

    },
    save() {
      this.$refs.simpleRules.validate().then(success => {
      if (success) {
          if(this.flg){
            this.FormData.flg=1
          }else{
            this.FormData.flg=0
          }
          this.FormData.acts=JSON.stringify(this.acts)
          if (this.FormData.id == '') {
            this.FormData.act = 'add'
          } else {
            this.FormData.act = 'mod'
          }
          this.$http.post('svr/admin_menu.php', this.FormData).then(res => {
              if (res.code == 20000) {
                this.showToast('success', '编辑成功!')
                this.get_data()
              }
          })
      }
     })
    },
    resolveUserStatusVariant(flg) {
      if (flg === '0') return 'warning'
      if (flg === '1') return 'success'
      return 'primary'
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.list-group-item {
  transition: all 1s
}
.tabledata {
  border-radius: 3px;
}
.b-table-sticky-header {
  max-height: calc(100vh - 440px) !important;
}
.custom-select {
  padding: 0.438rem 0rem 0.438rem 0.6rem;
  width: 3.4rem;
}
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
.filter {
  margin-bottom: 2rem;
}
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.1rem;
  font-weight: 600;
}
.card{
  margin-bottom: 0 !important;
}
.menu_main{
  height: calc(100vh - 328px) !important; 
}
.add_card{
    height: calc(100vh - 70px) !important; 
}
 
</style>

